import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import CounterUpTwo from '../component/counterup/CounterUpTwo';
import TeamOne from '../component/team/TeamOne';
import VideoTwo from '../component/video/VideoTwo';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
const Team = () => {

    return (
        <>
            <SEO title="Team" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne
                    title="Meet talent & experience"
                    paragraph="Elevate your business with custom AI-powered web and mobile apps to make your company stand out."
                    styleClass=""
                    mainThumb="/images/banner/banner-thumb-42.png"
                />
                <TeamOne />
                {/* <VideoTwo /> */}
                <CounterUpTwo />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default Team;