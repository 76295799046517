import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';


// Home Pages Import
import DigitalAgency from './pages/DigitalAgency';


// Service 
import ServiceOne from './pages/ServiceOne';
import ServiceTwo from './pages/ServiceTwo';
import ServiceDetails from './pages/ServiceDetails';

// Project 

import ProjectGridTwo from './pages/ProjectGridTwo';

import ProjectDetails from './pages/ProjectDetails';

// Pages 
import AboutUs from './pages/AboutUs';

import Team from './pages/Team';
import TeamDetails from './pages/TeamDetails';


import Testimonials from './pages/Testimonials';

import Contact from './pages/Contact';
import ErrorPage from './pages/404';


// Css Import
import './assets/scss/app.scss';


const App = () => {
  return (
    <Router>
		<ScrollToTop>
			<Routes>
			<Route path={process.env.PUBLIC_URL + "/"} element={<DigitalAgency />}/>

				<Route path={process.env.PUBLIC_URL + "/"} element={<DigitalAgency />}/>
				

			
				{/* Service */}
				<Route path={process.env.PUBLIC_URL + "/service-one/"} element={<ServiceOne />}/>
				<Route path={process.env.PUBLIC_URL + "/service-two/"} element={<ServiceTwo />}/>
				<Route path={process.env.PUBLIC_URL + "/service-details/:slug"} element={<ServiceDetails />}/>

				{/* Project  */}
		
				<Route path={process.env.PUBLIC_URL + "/project-grid-two"} element={<ProjectGridTwo />}/>
			
				<Route path={process.env.PUBLIC_URL + "/project-details/:slug"} element={<ProjectDetails />}/>

				{/* Pages  */}
				<Route path={process.env.PUBLIC_URL + "/about-us"} element={<AboutUs />}/>
		
				<Route path={process.env.PUBLIC_URL + "/team"} element={<Team />}/>
				<Route path={process.env.PUBLIC_URL + "/team-details/:slug"} element={<TeamDetails />}/>
				
				
				<Route path={process.env.PUBLIC_URL + "/testimonials"} element={<Testimonials />}/>
			
				<Route path={process.env.PUBLIC_URL + "/contact"} element={<Contact />}/>
				<Route path={process.env.PUBLIC_URL + "/404"} element={<ErrorPage />}/>
			

			</Routes>
		</ScrollToTop>
    </Router>
  )
}

export default App;
