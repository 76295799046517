import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
            Your Message has been successfully sent.
            Syntax Team Will Contact you Within Some hours
        </Alert>
    );
};

const FormTwo = () => {
    const form = useRef();
    const [result, setResult] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_4nthqvk', 'template_0sl3euk', form.current, 'oUawN1BIqOByfpVK7')
            .then((result) => {
                console.log(result.text);
                setResult(true);
            }, (error) => {
                console.log(error.text);
            });
        
        e.target.reset();
    };

    return (
        <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
            <div className="form-group">
                <label>Name</label>
                <input type="text" className="form-control" name="from_name" required />
            </div>
            <div className="form-group">
                <label>Email</label>
                <input type="email" className="form-control" name="reply_to" required />
            </div>
            <div className="form-group mb--40">
                <label>How can we help you?</label>
                <textarea className="form-control" name="message" rows="4"></textarea>
            </div>
            <div className="form-group">
                <button type="submit" className="axil-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn">Send</button>
            </div>
            <div className="form-group">
                {result ? <Result /> : null}
            </div>
        </form>
    );
};

export default FormTwo;
